[
  {
    "eng": {
      "title": "intro",
      "text": "<p>We are a- <bld>Bratislava,SK</bld> -based provider of IT development services </br>Our main focus is development of: <ul><li><bld>web applications</bld></li><li><bld>apps for mobile (iOS, Android)</bld></li> <li><bld>CRM solutions based on salesforce.com platform</bld></li></ul> Our portfolio of services is diverse, from development at its core, administration, maintenance, consultancy and analytics, to customer support of ongoing solutions<p>We stand out in:<ul><li><bld>competetive pricing</bld><li><bld>delivery quality</bld><li><bld>flexibility of smaller size company and agile team members</bld></ul></b></p></p><p>We cover solutions in <b>4 languages <bld>(EN, DE, CZ, SK)</bld></b></p>"
    },
    "cze": {
      "title": "úvod",
      "text": "<p>Jsme poskytovatelem služeb v IT </br>Naším hlavním zaměřením je vývoj: <ul><li><bld>webových aplikací</bld> </li><li><bld>aplikací pro mobily (iOS, Android)</bld></li> <li><bld>CRM založená na platformě salesforce.com</bld></li></ul> Naše portfolio služeb je rozmanité, od vývoje, správy, údržby, poradenství a analýzy až po zákaznickou podporu bežících řešení<p>Vynikáme:<ul><li><bld>bezkonkurenčními cenami</ bld><li><bld>kvalitou služeb</bld><li><bld>flexibilitou menší společnosti</bld></ul></b></p></p><p>Pokrýváme řešení ve <b>4 jazycích ​​<bld>(EN, DE, CZ, SK)</bld></b></p>"
    },
    "svk": {
      "title": "úvod",
      "text": "<p>Sme poskytovateľ služieb v IT </br>Naším hlavným zameraním je vývoj: <ul><li><bld>webových aplikácií</bld> </li><li><bld>aplikácií pre mobilné zariadenia (iOS, Android)</bld></li> <li><bld>CRM založené na platforme salesforce.com</bld></li></ul> Naše portfólio služieb je rôznorodé, od vývoja, správy, údržby, poradenstva a analýzy až po zákaznícku podporu bežiacich riešení<p>Naše hlavné zbrane na trhu sú:<ul><li><bld>bezkonkurenčné ceny</ bld><li><bld>kvalita dodávky</bld><li><bld>flexibilita menšej spoločnosti a agilita členov tímu</bld></ul></b></p></p><p>Pokrývame riešenia v <b>4 jazykoch ​​<bld>(EN, DE, CZ, SK)</bld></b></p>"
    },
    "deu": {
      "title": "über uns",
      "text": "<p>Wir sind ein in <bld>Bratislava, SK</bld> ansässiger Anbieter von IT-Entwicklungsdiensten. </br>Unser Hauptaugenmerk liegt auf der Entwicklung von: <ul><li><bld>Webanwendungen</bld> </li><li><bld>Apps für Mobilgeräte (iOS, Android)</bld></li> <li><bld>CRM-Lösungen basierend auf der salesforce.com-Plattform</bld></li></ul> Unser Dienstleistungsportfolio ist vielfältig, von der Kernentwicklung über Verwaltung, Wartung, Beratung und Analyse bis hin zum Kundensupport laufender Lösungen<p>Wir zeichnen uns aus durch:<ul><li><bld>wettbewerbsfähige Preise</ bld><li><bld>Lieferqualität</bld><li><bld>Flexibilität für kleinere Unternehmen und agile Teammitglieder</bld></ul></b></p></p><p>Wir decken Lösungen in <b>4 Sprachen <bld>(EN, DE, CZ, SK) </bld></b>ab</p> "
    }
  },

  {
    "eng": {
      "title": "salesforce.com",
      "text": "<bld><h1>development services</h1></bld><ul><li>design, build and deployment of salesforce.com (SFDC) applications using <bld>Apex, Visualforce, Aura</bld> and the <bld>LWC</bld> frameworks to leverage the full capabilities of the SFDC platform<li>maintain, modify and administer existing partners' SFDC solutions</ul><bld><h1>consultancy services</h1></bld><ul><li>recommending changes to current techniques, procedures, or efforts<li>creating reports, dashboards, and analytics to support business processes and company initiatives</ul><bld><h1>integration services</h1></bld><ul><li>integrating multiple systems with SFDC, including internal proprietary and third-party applications</ul><bld><h1>deployment services</h1></bld><ul><li>development of  user documentation describing configuration and usage of application in relation to business processes<li>educating partners and stakeholders in the workflows, business processes</li></ul><bld><h1>support services</h1></bld><ul><li>customer support of live applications</ul>"
    },
    "cze": {
      "title": "salesforce.com",
      "text": "<bld><h1>vývojové služby</h1></bld><ul><li>návrh, sestavení a nasazení aplikací salesforce.com (SFDC) pomocí <bld>Apex, Visualforce, Aura</bld> a <bld>LWC</bld> k využití všech možností platformy SFDC<li>údržba, úprava a správa řešení SFDC stávajících partnerů</ul><bld><h1>poradenské služby</h1></bld ><ul><li>doporučení změn současných technik, postupů nebo snah<li>vytváření sestav, řídicích panelů a analýz na podporu obchodních procesů a iniciativ společnosti</ul><bld><h1>integrační služby</h1> </bld><ul><li>integrace více systémů s SFDC, včetně interních proprietárních aplikací a aplikací třetích stran</ul><bld><h1>služby nasazení</h1></bld><ul><li> vývoj uživatelské dokumentace popisující konfiguraci a použití aplikace ve vztahu k obchodním procesům<li>vzdělávání partnerů a zainteresovaných stran v pracovních postupech, obchodních procesech</li></ul><bld><h1>služby podpory</h1></bld><ul><li>zákaznická podpora živých aplikací</ul>"
    },
    "svk": {
      "title": "salesforce.com",
      "text": "<bld><h1>vývojové služby</h1></bld><ul><li>návrh, zostavenie a nasadenie aplikácií salesforce.com (SFDC) pomocou <bld>Apex, Visualforce, Aura</bld> a <bld>LWC</bld> na využitie všetkých možností platformy SFDC<li>údržba, úprava a správa riešení SFDC u existujúcich partnerov</ul><bld><h1>poradenské služby</h1></bld ><ul><li>odporúčanie zmien zavedených postupov a techník<li>vytváranie zostáv, panelov a analýz na podporu obchodných procesov a iniciatív spoločnosti</ul><bld><h1>integračné služby</h1> </bld><ul><li>integrácia viacerých systémov s SFDC vrátane interných vlastných aplikácií a aplikácií tretích strán</ul><bld><h1>služby nasadenia</h1></bld><ul><li> vývoj užívateľskej dokumentácie popisujúcej konfiguráciu a používanie aplikácie vo vzťahu k obchodným procesom<li>vzdelávanie partnerov a zainteresovaných strán v oblasti pracovných postupov, obchodných procesov</li></ul><bld><h1>podporné služby</h1></bld><ul><li>zákaznícka podpora živých aplikácií</ul>"
    },
    "deu": {
      "title": "salesforce.com",
      "text": "<bld><h1>Entwicklungsdienste</h1></bld><ul><li>Design, Erstellung und Bereitstellung von salesforce.com (SFDC)-Anwendungen mit <bld>Apex, Visualforce, Aura</bld> und dem <bld>LWC</bld>-Frameworks, um die vollen Fähigkeiten der SFDC-Plattform zu nutzen<li>die SFDC-Lösungen bestehender Partner zu warten, zu ändern und zu verwalten</ul><bld><h1>Beratungsdienste</h1></bld ><ul><li>Empfehlen von Änderungen an aktuellen Techniken, Verfahren oder Bemühungen<li>Erstellen von Berichten, Dashboards und Analysen zur Unterstützung von Geschäftsprozessen und Unternehmensinitiativen</ul><bld><h1>Integrationsdienste</h1> </bld><ul><li>Integration mehrerer Systeme mit SFDC, einschließlich interner proprietärer und Drittanbieteranwendungen</ul><bld><h1>Bereitstellungsdienste</h1></bld><ul><li> Entwicklung einer Benutzerdokumentation, die die Konfiguration und Verwendung der Anwendung in Bezug auf Geschäftsprozesse beschreibt<li>Schulung von Partnern und Interessenvertretern in den Arbeitsabläufen, Geschäftsprozessen</li></ul><bld><h1>Unterstützungsdienste</h1></bld><ul><li>Kundensupport von Live-Anwendungen</ul>"
    }
  },
  {
    "eng": {
      "title": "web/mobile",
      "text": "<p>Technologies we use for web and mobile apps development:<ul><li><b><bld>frontend development</bld></b>: JavaScript, TypeScript, ReactJS framework, CSS, HTML, Android Studio/Java, React Native</li><li><bld>backend development</bld>: NodeJS, NextJS, PHP, Java <li><bld>databases</bld>: MongoDB, MySQL, Oracle</li><li><bld>business intelligence</bld>: Tableau, TIBCO Spotfire </ul>"
    },
    "cze": {
      "title": "web/mobile",
      "text": "<p>Technologie, které používáme pro vývoj webových a mobilních aplikací:<ul><li><b><bld>frontend</bld></b>: JavaScript, TypeScript, ReactJS framework, CSS, HTML, Android Studio/ Java, React Native</li><li><bld>backendu</bld>: NodeJS, NextJS, PHP, Java <li><bld>databáze</bld>: MongoDB, MySQL, Oracle</li><li><bld>business intelligence</bld>: Tableau, TIBCO Spotfire </ul>"
    },
    "svk": {
      "title": "web/mobile",
      "text": "<p>Technológie, ktoré používame na vývoj webových a mobilných aplikácií:<ul><li><b><bld>frontend:</bld></b>: JavaScript, TypeScript, ReactJS framework, CSS, HTML, Android Studio/ Java, React Native</li><li><bld>backend:</bld>: NodeJS, NextJS, PHP, Java <li><bld>databázy:</bld> MongoDB, MySQL, Oracle</li><li><bld>business intelligence</bld>: Tableau, TIBCO Spotfire </ul>"
    },
    "deu": {
      "title": "web/mobile",
      "text": "<p>Technologien, die wir für die Entwicklung von Web- und mobilen Apps verwenden:<ul><li><b><bld>Frontend-Entwicklung</bld></b>: JavaScript, TypeScript, ReactJS-Framework, CSS, HTML, Android Studio/ Java, React Native</li><li><bld>Backend-Entwicklung</bld>: NodeJS, NextJS, PHP, Java <li><bld>Datenbanken</bld>: MongoDB, MySQL, Oracle</li><li><bld>Business-Intelligence</bld>: Tableau, TIBCO Spotfire </ul>"
    }
  },
  {
    "eng": {
      "title": "contact",
      "text": "<p>tel:&nbsp&nbsp<bld>+421 905 585 952</bld></p><p>e-mail:&nbsp&nbsp<bld>info@zenix.studio</bld><br></p>"
    },
    "cze": {
      "title": "kontakt",
      "text": "<p>tel:&nbsp&nbsp<bld>+421 905 585 952</bld></p><p>e-mail:&nbsp&nbsp<bld>info@zenix.studio</bld><br></p>"
    },
    "svk": {
      "title": "kontakt",
      "text": "<p>tel:&nbsp&nbsp<bld>+421 905 585 952</bld></p><p>e-mail:&nbsp&nbsp<bld>info@zenix.studio</bld><br></p>"
    },
    "deu": {
      "title": "kontakt",
      "text": "<p>tel:&nbsp&nbsp<bld>+421 905 585 952</bld></p><p>e-mail:&nbsp&nbsp<bld>info@zenix.studio</bld><br></p>"
    }
  }
]
