import content0 from "../data/content.json";
import params from "./particles_my.json";
import keyboard from "../img/keyboard3.png";
import code from "../img/code2.jpg";
import guys from "../img/guys3.jpg";
import contact from "../img/contact2.jpg";

document.addEventListener(
  "DOMContentLoaded",
  function () {
    if (
      window.location.protocol == "http:" &&
      window.location.hostname != "localhost"
    ) {
      window.location =
        "https://" +
        window.location.hostname +
        window.location.pathname +
        window.location.hash;
    }
  },
  false
);

const imgArr = [code, keyboard, guys, contact];
let lang = "eng";
let idx_start = 0;
let idx_new = 0;

let content_container_div = document.querySelector(".content-container");

let langs = document.querySelectorAll(".clona-all");
let arrows = document.querySelectorAll(".arrow");
let larrow = document.querySelector(".aleft");
let rarrow = document.querySelector(".aright");
console.log(larrow);

function updatePrepare(ix0) {
  idx_new = ix0;
  switch (ix0) {
    case content0.length - 1:
      rarrow.classList.add("greyed");
      larrow.classList.remove("greyed");
      break;
    case 0:
      larrow.classList.add("greyed");
      rarrow.classList.remove("greyed");
      break;
    default:
      larrow.classList.remove("greyed");
      rarrow.classList.remove("greyed");
      break;
  }
  updateContent();
}

const reloadLang = function (lan0) {
  content_container_div.querySelectorAll(".tab").forEach((tab0, ix0) => {
    tab0.innerHTML = content0[ix0][lan0].title;
  });

  content_container_div.querySelectorAll(".moving").forEach((node0, idx) => {
    node0.querySelectorAll(".textik").forEach((nodex, ixx) => {
      nodex.innerHTML = content0[idx][lan0].text;
    });
  });

  content_container_div
    .querySelectorAll(".hidden_content_box")
    .forEach((node0, idx) => {
      node0.querySelectorAll(".textik").forEach((nodex, ixx) => {
        nodex.innerHTML = content0[idx][lan0].text;
      });
    });

  const flg_arr = Array.from(langs);
  flg_arr.forEach(
    (flg) => (flg.querySelector(".clona-svg10").style.filter = "opacity(50%) ")
  );
  const flg = flg_arr.find((flag) => flag.id === lan0);

  flg.querySelector(".clona-svg10").style.filter = "opacity(0%)";
};

langs.forEach((element) => {
  element.addEventListener("click", (e) => {
    reloadLang(e.target.closest(".clona-all").id.slice(0, 3));
  });
});

arrows.forEach((element) => {
  element.addEventListener("click", (e) => {
    console.log(e.target.closest("svg").dataset.direct);
    switch (e.target.closest("svg").dataset.direct) {
      case "left":
        if (idx_new > 0) {
          idx_new--;
          updateContent();
          rarrow.classList.remove("greyed");
          if (idx_new === 0) {
            e.target.closest("svg").classList.add("greyed");
          }
        }
        break;

      case "right":
        if (idx_new < content0.length - 1) {
          idx_new++;
          updateContent();

          larrow.classList.remove("greyed");
          if (idx_new === content0.length - 1)
            e.target.closest("svg").classList.add("greyed");
        }
        break;
    }
  });
});

const setUpContent = function () {
  const newDiv = document.createElement("div");
  const newH1 = document.createElement("div");
  newH1.classList.add("tab_container");
  let cls = "";

  content0.forEach((con_el2, idx2) => {
    idx2 === idx_new ? (cls = "spanBig") : (cls = "spanSmall");
    const newTab = document.createElement("div");
    newTab.innerHTML = con_el2[lang].title;
    newTab.classList.add(cls);
    newTab.classList.add("tab");
    newTab.addEventListener("click", (e) => {
      updatePrepare(idx2);
    });
    newH1.appendChild(newTab);
  });

  let newT;
  newT = document.createElement("div");
  newT.classList.add("text_container");
  content0.forEach((con_el, idx) => {
    const newP = document.createElement("div");
    newP.classList.add("pdiv");
    newP.classList.add("boxes");
    newP.classList.add("vanish");
    newP.classList.add("pic");
    const newPtext = document.createElement("div");
    const newPpic = document.createElement("div");
    newPtext.classList.add("textik");
    newPtext.innerHTML = con_el[lang].text;

    newPpic.innerHTML = "<div><img class='obr' src=" + imgArr[idx] + "></div>";
    const newPpic2 = newPpic.cloneNode(true);

    newPpic2.innerHTML =
      "<div><img class='obr2' src=" + imgArr[idx] + "></div>";

    newP.appendChild(newPtext);
    newP.appendChild(newPpic);
    newP.appendChild(newPpic2);
    newP.style.transform = "translate(" + (idx - idx_start) * 100 + "%, 0%)";
    const newP2 = newP.cloneNode(true);
    newP2.classList.add("hidden_content_box");
    if (idx === idx_start) {
      newP2.classList.remove("hidden");
    } else {
      newP2.classList.add("hidden");
    }
    newP.classList.add("moving");

    newT.appendChild(newP);
    newT.appendChild(newP2);

    // content_container_div.appendChild(newP);
  });

  newDiv.appendChild(newH1);
  newDiv.appendChild(newT);

  content_container_div.appendChild(newDiv);
};

const updateContent = function () {
  let cls = "";
  content_container_div.querySelectorAll(".tab").forEach((tab, idx0) => {
    idx0 === idx_new ? (cls = "spanBig") : (cls = "spanSmall");
    tab.classList.remove("spanSmall");
    tab.classList.remove("spanBig");
    tab.classList.add(cls);
  });

  content_container_div.querySelectorAll(".moving").forEach((node0, idx) => {
    node0.style = "";
    node0.style.transform = "translate(" + (idx - idx_new) * 100 + "%,0%)";
  });

  content_container_div
    .querySelectorAll(".hidden_content_box")
    .forEach((node0, idx) => {
      node0.style = "";
      node0.style.transform = "translate(" + (idx - idx_new) * 100 + "%,0%)";
      if (idx === idx_new) {
        node0.classList.remove("hidden");
      } else {
        node0.classList.add("hidden");
      }
    });
};

setUpContent();

reloadLang("eng");
particlesJS("particles-js", params);
